<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Reports</h1>
            </v-col>
            <v-col>
                <v-select
                    label="Choose a Report"
                    :items="reports"
                    item-text="title"
                    item-value="route"
                    :value="$route.path"
                    v-model="selectedReport"
                    @change="selectReport"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'ReportsView',
    data: () => {
        return {
            selectedReport: null,
            reports: [
                {
                    route: '/reports/usersBySite',
                    title: 'Users by Site'
                },
                {
                    route: '/reports/messagesBySite',
                    title: 'Messages by Site'
                },
            ]
        };
    },
    methods: {
        selectReport() {
            this.$router.push(this.selectedReport);
        }
    },
    mounted() {
        this.selectedReport = this.$route.path;
    }
}
</script>
