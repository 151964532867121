<template>
    <div>
        <v-card-subtitle>New Sites</v-card-subtitle>
        <v-card-text>
            <div class="text-h4 text--primary">{{ formatNumber(sitesThisMonth) }}</div>
            <div :class="sitesDifference >= 0 ? 'success--text' : 'error--text'" v-if="!loading">
                <span v-if="sitesDifference >= 0">+</span>{{ formatNumber(sitesDifference)}} vs last month
            </div>
        </v-card-text>
    </div>
</template>

<script>
import dayjs from "dayjs";
import formatNumber from "@/mixins/formatNumber";
var utc = require('dayjs/plugin/utc')

export default {
    name: 'DashboardSitesCard',
    mixins: [formatNumber],
    data: () => {
        return {
            loading: true,
            sitesThisMonth: 0,
            sitesLastMonth: 0
        };
    },
    methods: {

        async getData() {
            try {
                const sitesThisMonthResponse = await this.$http.get('/sites/totals', {
                    'fromTs': dayjs.utc().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                    'toTs': dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
                });

                const sitesLastMonthResponse = await this.$http.get('/sites/totals', {
                    'fromTs': dayjs.utc().startOf('month').subtract(1,'month').format('YYYY-MM-DD HH:mm:ss'),
                    'toTs': dayjs.utc().subtract(1,'month').format('YYYY-MM-DD HH:mm:ss'),
                });

                this.sitesThisMonth = sitesThisMonthResponse.data.total;
                this.sitesLastMonth = sitesLastMonthResponse.data.total;

                this.loading = false;

            } catch (e) {
                console.error(e);
            }
        }
    },
    computed: {
        sitesDifference() {
            return this.sitesThisMonth - this.sitesLastMonth;
        },
    },
    mounted() {
        dayjs.extend(utc)
        this.getData();
    }
}
</script>
