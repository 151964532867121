<template>
    <v-container fluid>
        <v-card
            :loading="loading"
        >
            <v-card-text>
                <v-row>
                    <v-dialog
                        ref="dialog"
                        v-model="showDatePicker"
                        :return-value.sync="dates"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-col>
                                <v-text-field
                                    :value="datesFormatted"
                                    label="Date Range"
                                    :error="datesError"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <v-date-picker
                            v-model="dates"
                            scrollable
                            range
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="showDatePicker = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(dates)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <v-col>
                        <v-autocomplete
                            v-model="site"
                            :loading="sitesLoading"
                            :items="sites"
                            :search-input.sync="search"
                            cache-items
                            hide-no-data
                            hide-details
                            item-text="site_name"
                            item-value="id"
                            label="Filter by Site"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-btn
                    color="primary"
                    @click="getReport"
                    :disabled="loading"
                    :loading="loading"
                >
                    Run Report
                </v-btn>
            </v-card-text>
            <v-card-text v-if="!loading">
                <bar-chart
                    :chart-data="chartData"
                    :options="chartOptions"
                    :styles="chartStyles"
                    ref="chart"
                ></bar-chart>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import dayjs from "dayjs";
import formatNumber from "@/mixins/formatNumber";
var utc = require('dayjs/plugin/utc')
import barChart from "@/components/barChart.vue";

export default {
    name: 'ReportsMessagesBySite',
    mixins: [formatNumber],
    components: {barChart},
    data: () => {
        return {
            loading: false,
            datesError: false,
            showDatePicker: false,
            dates:[],
            site: null,
            search: null,
            sitesLoading: false,
            sites: [],
            chartLabels: [],
            dateValues: [],
            chartData: {},
            chartStyles: {
                height: '200px',
                width: '100%',
                position: 'relative',
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display:true
                    }
                },
                scales: {
                    x:
                        {
                            stacked: true,
                            gridLines: {
                                display:false

                            }
                        },
                    y:
                        {
                            stacked: true,
                            ticks: {
                                precision: 0,
                                suggestedMin: 0

                            }

                        }
                }
            }

        }
    },
    computed: {
        datesFormatted() {
            if(this.dates.length === 2) {
                return this.dates[0] + ' - ' + this.dates[1];
            } else {
                return this.dates;
            }
        },
    },
    methods: {
        async getSites () {
            this.sitesLoading = true

            try {
                const response = await this.$http.get('/sites', {
                    'search': this.search,
                    'searchField': 'site_name',
                    'sort': ['site_name'],
                    'sortDesc': 0,
                    'page': 1,
                    'limit': 25
                });

                this.sites = response.data.sites.filter(e => {
                    return (e.site_name || '').toLowerCase().indexOf((this.search || '').toLowerCase()) > -1
                });
                this.sitesLoading = false;
            } catch (e) {
                console.error(e);
            }
        },
        async getReport () {
            let apiData = {
                'type': 'messagesBySite',
            }

            if(this.dates.length !== 2) {
                this.datesError = true;
                return;
            }

            this.loading = true;

            try {
                apiData.from = this.dates[0];
                apiData.to = this.dates[1];
                if(this.site) {
                    apiData.siteId = this.site;
                }

                const response = await this.$http.get('/chats_report', apiData )
                this.chartData.labels = response.data.labels;
                this.chartData.datasets = [];
                console.log(response.data.results);

                Object.entries(response.data.results).forEach(entry => {
                    let datasetLabel = entry[0];
                    let dataset = Object.entries(entry[1]).map(row => {
                        return row[1];
                    });
                    this.chartData.datasets.push({
                        label: datasetLabel,
                        data: dataset
                    });
                });

                console.log(this.chartData);

            } catch (e) {
                console.error(e);
            }

            this.loading = false;

        },
    },
    watch: {
        search (val) {
            val && val !== this.site && this.getSites()
        },
    },
    mounted() {
        dayjs.extend(utc)

        this.dates = [
            dayjs.utc().subtract(30, 'day').format('YYYY-MM-DD'),
            dayjs.utc().format('YYYY-MM-DD')
        ]
        this.getReport();
    }
}
</script>
