<template>
    <v-main>
    </v-main>
</template>


<script>
    export default {
        name: "Logout",
        data: () => {
            return {
            };
        },
        methods: {
        },
        mounted() {
            localStorage.removeItem('WalkyTalkyAdminToken');
            this.$store.methods.updateLoggedIn(false);
            this.$store.methods.updateAdmin({});
            this.$router.push({path:'/login'});
        }
    }
</script>
