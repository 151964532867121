<template>
    <Pie
        :data="chartData"
        :options="options"
    />
</template>

<script>
// DataPage.vue
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement)

export default {
    name: 'PieChart',
    components: { Pie },
    props: ['chartData','options','styles']
}
</script>
