
var formatCurrency  = {
    methods: {
        formatCurrency(number) {
            if(!number) {
                number = 0;
            }

            return parseFloat(number).toLocaleString('en-us',{style:'currency','currency': 'USD'});
        },
    }
};

export default formatCurrency;

