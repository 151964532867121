<template>
    <v-container fluid>
        <v-snackbar v-model="success" color="success" top :timeout="6000">
            {{ successMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="success = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="error" color="error" top :timeout="6000">
            <ul>
                <li v-for="(item,key) in errorMessages" :key="key">{{ item }}</li>
            </ul>
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="error = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col>
                <h1>Marketing Campaigns</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="columns"
                        :items="campaigns"
                        :loading="loading"
                        :server-items-length="totalCampaigns"
                        :options.sync="options"
                        no-data-text="No Campaigns Found"
                        :items-per-page="25"
                        :footer-props="footerProps"
                    >
                        <template v-slot:top>
                            <v-toolbar flat color="transparent">
                                <v-select
                                    hide-details
                                    v-model="timeframe"
                                    :items="timeframeItems"
                                    style="max-width:200px"
                                >
                                </v-select>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="showNewModal = true;">New Campaign</v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:item="{item}">
                            <tr>
                                <td>{{ item.id }}</td>
                                <td>{{ item.identifier }}</td>
                                <td class="text-right">{{ formatNumber(item.campaign_clicks) }}</td>
                                <td class="text-right">{{ formatNumber(item.account_signups) }}</td>
                                <td class="text-right">{{ formatNumber(item.account_verifications) }}</td>
                                <td class="text-right">{{ formatNumber(item.sites_added) }}</td>
                                <td class="text-right">{{ formatNumber(item.sites_verified) }}</td>
                                <td class="text-center">
                                    <v-btn icon @click="selectedCampaign = { ...item }; showEditModal = true;">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="selectedCampaign = { ...item }; showDeleteModal = true;">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="selectedCampaign = { ...item }; showCodeModal = true;">
                                        <v-icon>mdi-code-tags</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        @click="expandedCampaigns.push(item.id)"
                                        v-if="!expandedCampaigns.includes(item.id)"
                                    >
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        @click="expandedCampaigns = expandedCampaigns.filter(value => value !== item.id)"
                                        v-else
                                    >
                                        <v-icon>mdi-chevron-up</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <marketing-campaigns-breakdown-table
                                v-if="expandedCampaigns.includes(item.id)"
                                :campaignId="item.id"
                                :timeframe="timeframe"
                            ></marketing-campaigns-breakdown-table>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="showNewModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>New Campaign</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="identifier"
                        label="Identifier"
                    ></v-text-field>
                    <v-textarea
                        v-model="agentSignupPostbackUrl"
                        label="Agent Signup Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                    <v-textarea
                        v-model="agentVerifiedPostbackUrl"
                        label="Agent Verified Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                    <v-textarea
                        v-model="siteAddedPostbackUrl"
                        label="Site Created Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                    <v-textarea
                        v-model="siteVerifiedPostbackUrl"
                        label="Site Verified Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showNewModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="createCampaign">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showEditModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>Edit Campaign</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="selectedCampaign.identifier"
                        label="Identifier"
                    ></v-text-field>
                    <v-textarea
                        v-model="selectedCampaign.agent_signup_postback_url"
                        label="Agent Signup Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                    <v-textarea
                        v-model="selectedCampaign.agent_verified_postback_url"
                        label="Agent Verified Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                    <v-textarea
                        v-model="selectedCampaign.site_added_postback_url"
                        label="Site Created Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                    <v-textarea
                        v-model="selectedCampaign.site_verified_postback_url"
                        label="Site Verified Postback URL"
                        auto-grow
                        hint="Use __TRACKINGID__ to return click id"
                    ></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showEditModal=false;selectedCampaign={};">Cancel</v-btn>
                    <v-btn text color="primary" @click="updateCampaign">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showDeleteModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>Delete Campaign</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this campaign?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="selectedCampaign={};showDeleteModal=false;">Cancel</v-btn>
                    <v-btn text color="error" @click="deleteCampaign">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showCodeModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>Tracking Parameters</v-card-title>
                <v-card-text>
                    Send traffic to either of these links. Replace "REPLACEWITHCLICKID" with the click id macro for the platform
                    <v-text-field
                        readonly
                        label="walkytalky.io"
                        :value="'https://www.walkytalky.io/'+trackingParams"
                        append-icon="mdi-content-copy"
                        @click:append="copySnippet('https://www.walkytalky.io/')"
                    >
                    </v-text-field>
                    <v-text-field
                        readonly
                        label="getwalkytalky.io"
                        :value="'https://getwalkytalky.io/'+trackingParams"
                        append-icon="mdi-content-copy"
                        @click:append="copySnippet('https://getwalkytalky.io/')"
                    >
                    </v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showCodeModal=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import formatDateTime from "@/mixins/formatDateTime";
import formatNumber from "@/mixins/formatNumber";
import MarketingCampaignsBreakdownTable from "@/components/MarketingCampaignsBreakdownTable.vue";

export default {
    name: 'MarketingCampaignsView',
    components: {MarketingCampaignsBreakdownTable},
    mixins: [formatDateTime, formatNumber],
    data: () => {
        return {
            success: false,
            successMessage: '',
            error: false,
            errorMessages: [],
            expandedCampaigns: [],
            loading: false,
            showNewModal: false,
            showEditModal: false,
            showDeleteModal: false,
            showCodeModal: false,
            campaigns: [],
            selectedCampaign: {},
            identifier: '',
            agentSignupPostbackUrl: '',
            agentVerifiedPostbackUrl: '',
            siteAddedPostbackUrl: '',
            siteVerifiedPostbackUrl: '',
            totalCampaigns: 0,
            timeframe: 24,
            timeframeItems: [
                {
                    text: 'last 24 hours',
                    value: 24,
                },
                {
                    text: 'last 48 hours',
                    value: 48,
                },
                {
                    text: 'last 7 days',
                    value: 168,
                },
                {
                    text: 'last 14 days',
                    value: 336,
                },
                {
                    text: 'last 30 days',
                    value: 720,
                },
            ],
            options: null,
            footerProps: {
                'items-per-page-options': [10, 25, 50, 100]
            },
            columns:[
                {text: 'ID', value: 'id'},
                {text: 'Campaign', value: 'identifier'},
                {text: 'Clicks', value: 'campaign_clicks', align: 'right'},
                {text: 'Signups', value: 'account_signups', align: 'right'},
                {text: 'Accounts Verified', value: 'account_verifications', align: 'right'},
                {text: 'Sites', value: 'sites_added', align: 'right'},
                {text: 'Sites Verified', value: 'sites_verified', align: 'right'},
                {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
            ],
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            try {
                const response = await this.$http.get('/inhouseCampaigns', {
                    'search': this.search,
                    'sort': this.options.sortBy,
                    'sortDesc': this.options.sortDesc,
                    'page': this.options.page,
                    'limit': this.options.itemsPerPage,
                    'timeframe': this.timeframe,
                });

                this.campaigns = response.data.inhouse_campaigns;
                this.totalCampaigns = response.data.total;
                this.loading = false;

            } catch(e) {
                console.error('error accessing campaigns');
            }

        },
        async createCampaign() {
            try {
                await this.$http.post("/inhouseCampaigns", {
                    'identifier': this.identifier,
                    'agentSignupPostbackUrl': this.agentSignupPostbackUrl,
                    'agentVerifiedPostbackUrl': this.agentVerifiedPostbackUrl,
                    'siteAddedPostbackUrl': this.siteAddedPostbackUrl,
                    'siteVerifiedPostbackUrl': this.siteVerifiedPostbackUrl,
                })

            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;
                return;
            }

            this.selectedCampaign = {
                'identifier': this.identifier
            }
            this.identifier = '';
            this.agentSignupPostbackUrl = '';
            this.agentVerifiedPostbackUrl = '';
            this.siteAddedPostbackUrl = '';
            this.siteVerifiedPostbackUrl = '';
            this.showNewModal = false;
            this.success = true;
            this.successMessage = "Campaign Created!";
            this.getData();
            this.showCodeModal = true;

        },
        async updateCampaign() {
            try {
                await this.$http.post("/inhouseCampaigns/"+this.selectedCampaign.id, {
                    'identifier': this.selectedCampaign.identifier,
                    'agentSignupPostbackUrl': this.selectedCampaign.agent_signup_postback_url ?
                        this.selectedCampaign.agent_signup_postback_url : '',
                    'agentVerifiedPostbackUrl': this.selectedCampaign.agent_verified_postback_url ?
                        this.selectedCampaign.agent_verified_postback_url : '',
                    'siteAddedPostbackUrl': this.selectedCampaign.site_added_postback_url ?
                        this.selectedCampaign.site_added_postback_url : '',
                    'siteVerifiedPostbackUrl': this.selectedCampaign.site_verified_postback_url ?
                        this.selectedCampaign.site_verified_postback_url : '',
                })

                this.selectedCampaign = {};
                this.showEditModal = false;
                this.success = true;
                this.successMessage = "Campaign Updated!";
                this.getData();

            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }

        },
        async deleteCampaign() {
            try {
                await this.$http.post("/inhouseCampaigns/"+this.selectedCampaign.id+'/delete')

                this.selectedCampaign = {};
                this.showDeleteModal = false;
                this.success = true;
                this.successMessage = "Campaign Deleted!";
                this.getData();

            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }

        },
        copySnippet (url) {
            try {
                navigator.clipboard.writeText(url+this.trackingParams);
                this.successMessage = "Parameters copied to clipboard";
                this.success = true;
            } catch (err) {
                this.errorMessages = ["Unable to copy to clipboard"];
                this.error = true;
            }

        },
    },
    computed: {
        trackingParams() {
            return '?utm_campaign='+this.selectedCampaign.identifier+'&tracking_id=REPLACEWITHCLICKID';
        }
    },
    watch: {
        options: {
            handler(newVal, oldVal) {
                if(oldVal !== null) {
                    this.getData();
                }
            },
            deep: true
        },
        timeframe() {
            this.getData();
        },

    },
    mounted() {
        this.getData();
    }
}
</script>
