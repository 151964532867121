<template>
    <v-card :loading="loading"
            class="mb-5"
    >
        <template slot="progress">
            <v-progress-linear
                indeterminate
            ></v-progress-linear>
        </template>
        <v-card-title>Plan Uptake</v-card-title>
        <v-card-text>
            <pie-chart
                v-if="!loading"
                :chart-data="chartData"
                :options="chartOptions"
                ref="chart"
            ></pie-chart>
        </v-card-text>
    </v-card>
</template>

<script>

import pieChart from "@/components/pieChart.vue";

export default {
    name: 'DashboardPlansCard',
    components: { pieChart },
    data: () => {
        return {
            loading: true,
            chartLabels: [],
            dateValues: [],
            chartData: {},
            chartStyles: {
                height: '200px',
                width: '100%',
                position: 'relative',
            },
            chartOptions: {
                cutout: '50%'
            }
        };
    },
    methods: {

        async getData() {
            try {
                const response = await this.$http.get('/plans_report');

                this.dateValues = response.data.results.map(row => row.total);
                this.chartLabels = response.data.results.map(row => row.label);
                this.generateChartData();

                this.loading = false;

            } catch (e) {
                console.error(e);
            }
        },
        generateChartData() {
            this.chartData = {
                labels: this.chartLabels,
                datasets: [
                    {
                        data: this.dateValues,
                        backgroundColor: [
                            "#3366CC",
                            "#DC3912",
                            "#FF9900",
                            "#109618",
                            "#990099",
                            "#3B3EAC",
                            "#0099C6",
                            "#DD4477",
                            "#66AA00",
                            "#B82E2E",
                            "#316395",
                            "#994499",
                            "#22AA99",
                            "#AAAA11",
                            "#6633CC",
                            "#E67300",
                            "#8B0707",
                            "#329262",
                            "#5574A6",
                            "#3B3EAC"
                        ]
                    },
                ]
            }
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
