import axios from "axios";

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use(function (config) {
    config.headers = {
        'adminToken': localStorage.getItem('WalkyTalkyAdminToken'),
        "Content-Type": "multipart/form-data"
    }
    return config;

});

const api = {
    get: function(endpoint, data) {
        return axios.get(endpoint, {
            params: data
        });
    },
    post: function(endpoint, data) {
        var postData = new FormData;
        if(data) {
            Object.keys(data).forEach(key => postData.append(key, data[key]));
        }
        return axios.post(endpoint, postData);
    }
};
export default api;
