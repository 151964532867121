
var formatNumber  = {
    methods: {
        formatNumber(number) {
            if(!number) {
                return 0;
            }

            return number.toLocaleString('en-US');
        }
    }
};

export default formatNumber;

