<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Dashboard</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <dashboard-platform-totals></dashboard-platform-totals>
                <dashboard-plans-card></dashboard-plans-card>
            </v-col>
            <v-col>
                <dashboard-users-card></dashboard-users-card>
                <dashboard-messages-card></dashboard-messages-card>
                <dashboard-emails-card></dashboard-emails-card>
            </v-col>
            <v-col>
                <dashboard-g-a-page-views-card></dashboard-g-a-page-views-card>
                <dashboard-this-month-card></dashboard-this-month-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import DashboardUsersCard from "@/components/DashboardUsersCard.vue";
import DashboardMessagesCard from "@/components/DashboardMessagesCard.vue";
import DashboardEmailsCard from "@/components/DashboardEmailsCard.vue";
import DashboardPlansCard from "@/components/DashboardPlansCard";
import DashboardPlatformTotals from "@/components/DashboardTotalsCard";
import DashboardThisMonthCard from "@/components/DashboardThisMonthCard";
import DashboardGAPageViewsCard from "@/components/DashboardGAPageViewsCard";
export default {
    name: 'DashboardView',
    components: {
        DashboardGAPageViewsCard,
        DashboardThisMonthCard,
        DashboardPlatformTotals,
        DashboardPlansCard,
        DashboardEmailsCard,
        DashboardMessagesCard,
        DashboardUsersCard,
    }
}
</script>
