<template>
    <v-container fluid>
        <v-snackbar v-model="success" color="success" top :timeout="6000">
            {{ successMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="success = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="error" color="error" top :timeout="6000">
            <ul>
                <li v-for="(item,key) in errorMessages" :key="key">{{ item }}</li>
            </ul>
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="error = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col>
                <h1>Settings</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Account Details</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="name"
                            label="Name"
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            label="Email"
                        ></v-text-field>
                        <v-btn color="primary" @click="saveDetails">Save</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Change Password</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="password"
                            label="Current Password"
                            type="password"
                        ></v-text-field>
                        <v-text-field
                            v-model="passwordNew"
                            label="New Password"
                            type="password"
                        ></v-text-field>
                        <v-text-field
                            v-model="passwordNew2"
                            label="New Password (again)"
                            type="password"
                        ></v-text-field>
                        <v-btn color="primary" @click="savePassword">Save</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
    name: "Settings",
    data: () => {
        return {
            success: false,
            successMessage: '',
            error: false,
            errorMessages: [],
            name: '',
            email: '',
            password: '',
            passwordNew: '',
            passwordNew2: ''
        };
    },
    methods: {
        async saveDetails() {
            try {
                const response = await this.$http.post('/admins/settings', {
                    name: this.name,
                    email: this.email
                });
                this.$store.methods.updateAdmin(response.data.admin);
                this.success = true;
                this.successMessage = "Account details updated.";
            } catch(e) {
                this.errorMessages = e.response.data.errors;
                this.error = true;
            }
        },
        async savePassword() {
            try {
                await this.$http.post('/admins/password', {
                    password: this.password,
                    passwordNew: this.passwordNew,
                    passwordNew2: this.passwordNew2
                });
                this.success = true;
                this.successMessage = "Password updated.";
            } catch(e) {
                this.errorMessages = e.response.data.errors;
                this.error = true;
            }

        }
    },
    mounted() {
        this.name = this.$store.data.admin.name;
        this.email = this.$store.data.admin.email;
    }
}
</script>
