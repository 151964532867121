<template>
    <v-container fluid v-if="!loading">
        <v-snackbar v-model="success" color="success" top :timeout="6000">
            {{ successMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="success = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="error" color="error" top :timeout="6000">
            <ul>
                <li v-for="(item,key) in errorMessages" :key="key">{{ item }}</li>
            </ul>
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="error = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col>
                <h1>Agent: {{ agent.name }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-card>
                    <v-card-title>Account Details</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="agent.id"
                            readonly
                            label="ID" />
                        <v-text-field
                            v-model="agent.name"
                            label="Name" />
                        <v-text-field
                            v-model="agent.email"
                            label="Email" />
                        <v-text-field
                            v-model="agent.added_ts"
                            readonly
                            label="Created" />
                        <v-text-field
                            v-model="agent.referrer"
                            readonly
                            label="Referrer" />
                        <v-checkbox
                            v-model="agent.status"
                            label="Active"
                            :false-value="0"
                            :true-value="1"
                        ></v-checkbox>
                        <v-btn color="primary" @click="saveDetails">Update</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card>
                    <v-card-title>Sites</v-card-title>
                    <v-list>
                        <v-list-item v-for="site in sites" :key="site.id" :to="'/sites/'+site.id">
                            <v-list-item-title>
                                {{ site.title }}
                                <v-chip v-if="site.owner_id == $route.params.id">Owner</v-chip>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import formatNumber from "@/mixins/formatNumber";

export default {
    name: 'AgentView',
    mixins: [formatNumber],
    data: () => {
        return {
            success: false,
            successMessage: '',
            error: false,
            errorMessages: [],
            loading: true,
            agent: {},
            websites: []
        };
    },
    methods: {
        async getData() {
            try {
                this.loading = true;
                const response = await this.$http.get('/agents/'+this.$route.params.id);
                this.agent = response.data.agent;
                const siteResponse = await this.$http.get('/agents/' + this.$route.params.id + '/sites')
                this.sites = siteResponse.data.websites;
                this.loading = false;
            } catch(e) {
                console.error(e);
            }
        },
        async saveDetails() {
            try {
                const updateResponse = await this.$http.post('/agents/'+this.$route.params.id, this.agent);
                this.success = true;
                this.successMessage = "Details Updated!";
            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
