<template>
    <v-container fluid v-if="!loading">
        <v-snackbar v-model="success" color="success" top :timeout="6000">
            {{ successMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="success = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="error" color="error" top :timeout="6000">
            <ul>
                <li v-for="(item,key) in errorMessages" :key="key">{{ item }}</li>
            </ul>
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="error = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col>
                <h1>Site: {{ site.title }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-card>
                    <v-card-title>Site Details</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="site.title" label="Site Name"></v-text-field>
                        <v-text-field readonly :value="site.added_ts" label="Added"></v-text-field>
                        <v-text-field readonly :value="owner.name" label="Owner">
                            <template v-slot:append-outer>
                                <v-btn icon :to="'/agents/'+owner.id">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-text-field v-model="site.url" label="Web Address"></v-text-field>
                        <v-checkbox
                            v-model="site.freebie"
                            label="Freebie"
                            :false-value="0"
                            :true-value="1"
                        ></v-checkbox>
                        <v-checkbox
                            v-model="site.status"
                            label="Active"
                            :false-value="0"
                            :true-value="1"
                        ></v-checkbox>
                        <v-checkbox
                            v-model="site.free_trial"
                            label="Free Trial"
                            :false-value="0"
                            :true-value="1"
                        ></v-checkbox>
                        <v-menu
                            v-model="showFreeTrialStartTsPicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="site.free_trial_start_ts"
                                    label="Free Trial Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    @click:clear="site.free_trial_start_ts = null"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="site.free_trial_start_ts"
                                @input="showFreeTrialStartTsPicker = false"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu
                            v-model="showFreeTrialEndTsPicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="site.free_trial_end_ts"
                                    label="Free Trial End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    @click:clear="site.free_trial_end_ts = null"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="site.free_trial_end_ts"
                                @input="showFreeTrialEndTsPicker = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-card-text>
                    <v-card-subtitle>
                        <h3>Chat Settings</h3>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field v-model="site.office_hours" label="Office Hours"></v-text-field>
                        <v-text-field v-model="site.chat_header_text" label="Header Text"></v-text-field>
                        <v-text-field v-model="site.chat_welcome_message" label="Welcome Message"></v-text-field>
                        <v-checkbox
                            v-model="site.chat_hide_branding"
                            label="Hide Branding"
                            :false-value="0"
                            :true-value="1"
                        ></v-checkbox>
                    </v-card-text>
                    <v-card-subtitle>
                        <h3>Article Settings</h3>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field v-model="site.posts_title" label="Title"></v-text-field>
                        <v-text-field v-model="site.posts_intro" label="Introduction Text"></v-text-field>
                        <v-text-field v-model="site.posts_slug" label="Slug"></v-text-field>
                    </v-card-text>
                    <v-card-subtitle>
                        <h3>Campaign Settings</h3>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field v-model="site.campaigns_salutation" label="Salutation"></v-text-field>
                        <v-checkbox
                            v-model="site.campaigns_hide_unsubscribe"
                            label="Hide Unsubscribe Link"
                            :false-value="0"
                            :true-value="1"
                        ></v-checkbox>
                        <v-btn color="primary" @click="saveDetails">Update</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mb-6">
                    <v-card-title>Site Plans</v-card-title>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Stripe Customer ID</v-list-item-title>
                            <v-list-item-subtitle>{{ site.stripe_customer_id }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-for="plan in $store.data.plans" :key="plan.id">
                        <v-list-item-title>{{ plan.name }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon color="success"
                                    v-if="currentPlanIds.includes(plan.id)
                                    || access.includes('freebie')
                                    || access.includes('trial')"
                            >
                                mdi-check-circle
                            </v-icon>
                            <v-icon color="grey lighten-1" v-else>
                                mdi-check-circle
                            </v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-card>

                <v-card class="">
                    <v-card-title>Site Stats</v-card-title>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>Agents</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.agents) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Users</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.users) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>User Fields</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.user_fields) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Messages</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.messages) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Posts</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.posts) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Campaigns</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.campaigns) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Emails</v-list-item-title>
                            <v-list-item-action-text>{{ formatNumber(stats.emails) }}</v-list-item-action-text>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Bot Update Date</v-list-item-title>
                                <v-list-item-subtitle>{{ site.bot_build_ts }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import formatNumber from "@/mixins/formatNumber";

export default {
    name: 'SiteView',
    mixins: [formatNumber],
    data: () => {
        return {
            success: false,
            successMessage: '',
            error: false,
            errorMessages: [],
            loading: true,
            site: {},
            owner: {},
            stats: {},
            showFreeTrialStartTsPicker: false,
            showFreeTrialEndTsPicker: false,
            access: null
        };
    },
    methods: {
        async getData() {
            try {
                this.loading = true;
                const response = await this.$http.get('/sites/'+this.$route.params.id);
                this.site = response.data.site;
                this.owner = response.data.owner;
                this.access = response.data.access;
                this.loading = false;
            } catch(e) {
                console.error(e);
            }
        },
        async getStats() {
            const statsResponse = await this.$http.get('/sites/'+this.$route.params.id+'/stats');
            this.stats = statsResponse.data

        },
        async saveDetails() {
            try {
                const updateResponse = await this.$http.post('/sites/'+this.$route.params.id, {
                    'site': JSON.stringify(this.site)
                });
                this.success = true;
                this.successMessage = "Details Updated!";
            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }

        }
    },
    computed: {
        currentPlanIds() {
            let plans = [];
            this.access.forEach(plan => {
                plans.push(plan.purchase_id);
            });
            return plans;
        },
    },
    mounted() {
        this.getData();
        this.getStats();
    }
}
</script>
