<template>
    <v-card class="mb-5">
        <v-card-title>Page Views - Google Analytics</v-card-title>
        <iframe
            width="600"
            height="300"
            src="https://lookerstudio.google.com/embed/reporting/9e3e8b94-5e70-48ea-8433-94b15923f224/page/ae9DD"
            frameborder="0"
            style="border:0"
            allowfullscreen
        ></iframe>
    </v-card>
</template>

<script>
import DashboardRevenueCard from "@/components/DashboardRevenueCard.vue";
import DashboardSitesCard from "@/components/DashboardSitesCard.vue";
import DashboardAgentsCard from "@/components/DashboardAgentsCard.vue";

export default {
    name: 'DashboardGAPageViewsCard',
    components: {
        DashboardAgentsCard, DashboardSitesCard, DashboardRevenueCard
    },
}
</script>
