<template>
    <div style="display: contents">
        <tr v-if="loading">
            <td class="text-center" colspan="7">Loading</td>
        </tr>
        <tr
            v-else-if="breakdownData.length > 0"
            v-for="(row,key) in breakdownData"
            :key="key"
        >
            <td></td>
            <td class="text-right">{{ row.geo }}</td>
            <td class="text-right">{{ formatNumber(row.clicks) }}</td>
            <td class="text-right">{{ formatNumber(row.account_signups) }}</td>
            <td class="text-right">{{ formatNumber(row.account_verifications) }}</td>
            <td class="text-right">{{ formatNumber(row.sites_added) }}</td>
            <td class="text-right">{{ formatNumber(row.sites_verified) }}</td>
            <td class="text-center"></td>
        </tr>
        <tr v-else>
            <td class="text-center" colspan="7">No Geo Breakdown Data</td>
        </tr>
    </div>
</template>

<script>
import formatNumber from "@/mixins/formatNumber";

export default {
    name: 'MarketingCampaignsBreakdownTable',
    mixins: [formatNumber],
    props: ['campaignId','timeframe'],
    data: () => {
        return {
            loading: false,
            breakdownData: [],
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            const response = await this.$http.get('/inhouseCampaigns/'+this.campaignId+'/breakdown', {
                'timeframe': this.timeframe,
            });
            if(response.data.geos) {
                this.breakdownData = response.data.geos.sort((a,b) => {
                    return parseInt(b.clicks) - parseInt(a.clicks);
                });
            }
            this.loading = false;
        },
    },
    watch: {
        timeframe() {
            this.getData();
        },
    },
    mounted() {
        this.getData();
    }
}
</script>
<style scoped>
td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    height: 48px;
    padding: 0 16px;
    line-height:48px;
    font-size: 0.875rem;
}

</style>
