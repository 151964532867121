import dayjs from 'dayjs';

var formatDateTime  = {
    methods: {
        formatMysqlDateTime(datetime) {
            if(!datetime) {
                return '';
            }

            return dayjs(datetime, 'YYYY-MM-DD HH:mm:ss').format('MMM D, YYYY h:mma');
        },
        formatMysqlDateTimeDateOnly(datetime) {
            if(!datetime) {
                return '';
            }

            return dayjs(datetime, 'YYYY-MM-DD HH:mm:ss').format('MMM D, YYYY');
        },
        formatMysqlDate(date) {
            if(!date) {
                return '';
            }

            return dayjs(date, 'YYYY-MM-DD').format('MMM D, YYYY');
        },
        formatMysqlDateReverse(formattedDate) {
            if(!formattedDate) {
                return '';
            }

            return dayjs(formattedDate, 'MMM D, YYYY').format('YYYY-MM-DD');

        }

    }
};

export default formatDateTime;

