<template>
    <v-card :loading="loading"
            class="mb-5"
    >
        <template slot="progress">
            <v-progress-linear
                indeterminate
            ></v-progress-linear>
        </template>
        <v-card-title>Users Trend</v-card-title>
        <v-card-text>
            <bar-chart
                v-if="!loading"
                :chart-data="chartData"
                :options="chartOptions"
                :styles="chartStyles"
                ref="chart"
            ></bar-chart>
        </v-card-text>
    </v-card>
</template>

<script>
import barChart from "@/components/barChart.vue";

export default {
    name: 'DashboardUsersCard',
    components: {barChart},
    data: () => {
        return {
            loading: true,
            chartLabels: [],
            dateValues: [],
            chartData: {},
            chartStyles: {
                height: '200px',
                width: '100%',
                position: 'relative',
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display:false
                    }
                },
                scales: {
                    x:
                        {
                            gridLines: {
                                display:false

                            }
                        },
                    y:
                        {
                            ticks: {
                                precision: 0,
                                suggestedMin: 0

                            }

                        }
                }
            }
        };
    },
    methods: {

        async getData() {
            try {
                const response = await this.$http.get('/users_report', {
                    'type': 'newUsersTrend',
                    'days':30
                });

                this.dateValues = response.data.results.map(row => row.value);
                this.chartLabels = response.data.results.map(row => row.label);
                this.generateChartData();

                this.loading = false;

            } catch (e) {
                console.error(e);
            }
        },
        generateChartData() {
            this.chartData = {
                labels: this.chartLabels,
                datasets: [
                    {
                        label: 'New Users',
                        backgroundColor: this.$vuetify.theme.themes.light.primary,
                        data: this.dateValues
                    }
                ]
            }
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
