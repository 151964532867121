<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Payments</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="columns"
                        :items="payments"
                        :loading="loading"
                        :server-items-length="totalPayments"
                        :options.sync="options"
                        no-data-text="No Payments Found"
                        :items-per-page="25"
                        :footer-props="footerProps"
                    >
                        <template v-slot:item.site_name="{ item }">
                            <router-link :to="'/sites/'+item.id">{{ item.site_name }}</router-link>
                        </template>
                        <template v-slot:item.amount="{ item }">
                            {{ formatCurrency(item.amount) }}
                        </template>
                        <template v-slot:item.ts="{ item }">
                            {{ formatMysqlDateTime(item.ts) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import formatDateTime from "@/mixins/formatDateTime";
import formatCurrency from "@/mixins/formatCurrency";

export default {
    name: 'PaymentsView',
    mixins: [formatDateTime, formatCurrency],
    data: () => {
        return {
            loading: false,
            payments: [],
            totalPayments: 0,
            options: null,
            search: '',
            footerProps: {
                'items-per-page-options': [10, 25, 50, 100]
            },
            columns:[
                {text: 'ID', value: 'id'},
                {text: 'Site Name', value: 'site_name'},
                {text: 'Date', value: 'ts'},
                {text: 'Amount', value: 'amount'},
            ],
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            try {
                const response = await this.$http.get('/payments', {
                    'search': this.search,
                    'sort': this.options.sortBy,
                    'sortDesc': this.options.sortDesc,
                    'page': this.options.page,
                    'limit': this.options.itemsPerPage,
                });

                this.payments = response.data.payments;
                this.totalPayments = response.data.total;
                this.loading = false;

            } catch(e) {
                console.error('error accessing sites');
            }

        },
    },
    watch: {
        options: {
            handler(newVal, oldVal) {
                if(oldVal !== null) {
                    this.getData();
                }
            },
            deep: true
        },

    },
    mounted() {
        this.getData();
    }
}
</script>
