<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Agents</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-select
                                    class="pt-0 mt-0"
                                    hide-details
                                    label="Agent Status"
                                    :items="statusFilter"
                                    v-model="status"
                                    @change="performSearch"
                                ></v-select>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search by Name, ID or Email Address"
                                    single-line
                                    hide-details
                                    @keypress.exact.enter="performSearch"
                                    @click:append="performSearch"
                                    class="pt-0 mt-0"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="columns"
                        :items="agents"
                        :loading="loading"
                        :server-items-length="totalAgents"
                        :options.sync="options"
                        no-data-text="No Agents Found"
                        :items-per-page="25"
                        :footer-props="footerProps"
                    >
                        <template v-slot:item.added_ts="{ item }">
                            {{ formatMysqlDateTime(item.added_ts) }}
                        </template>
                        <template v-slot:item.email_verified="{ item }">
                            <v-icon
                                color="success"
                                v-if="parseInt(item.email_verified) === 1"
                            >mdi-check-circle</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon :to="'/agents/'+item.id">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import formatDateTime from "@/mixins/formatDateTime";

export default {
    name: 'AgentsView',
    mixins: [formatDateTime],
    data: () => {
        return {
            loading: false,
            agents: [],
            totalAgents: 0,
            options: null,
            search: '',
            status: 1,
            statusFilter: [
                {
                    value: 2,
                    text: 'All'
                },
                {
                    value: 1,
                    text: 'Active'
                },
                {
                    value: 0,
                    text: 'Deleted'
                },
            ],
            footerProps: {
                'items-per-page-options': [10, 25, 50, 100]
            },
            columns:[
                {text: 'ID', value: 'id'},
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Verified', value: 'email_verified'},
                {text: 'Added', value: 'added_ts'},
                {text: 'Actions', value: 'actions', sortable:false}
            ],
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            try {
                const response = await this.$http.get('/agents', {
                    'search': this.search,
                    'sort': this.options.sortBy,
                    'sortDesc': this.options.sortDesc,
                    'page': this.options.page,
                    'limit': this.options.itemsPerPage,
                    'status': this.status
                });

                this.agents = response.data.agents;
                this.totalAgents = response.data.total;
                this.loading = false;

            } catch(e) {
                console.error('error accessing agents');
            }

        },
        performSearch() {
            this.options.page = 1;
            this.getData();
        }
    },
    watch: {
        options: {
            handler(newVal, oldVal) {
                if(oldVal !== null) {
                    this.getData();
                }
            },
            deep: true
        },

    },
    mounted() {
        this.getData();
    }
}
</script>
