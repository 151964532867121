<template>
    <v-container fluid class="fill-height bg-gradient d-block">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4" lg="3">
                <v-card class="elevation-15 pa-3 pa-sm-5  ma-3 ma-sm-0">
                    <v-toolbar flat>
                        <v-toolbar-title>WalkyTalky Admin</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" lazy-validation v-model="valid" @submit="login" @submit.prevent>
                            <v-text-field
                                    v-model="email"
                                    label="Email Address"
                                    name="email"
                                    type="text"
                                    autofocus
                                    required
                                    :rules="validationRules.email"
                                    :error-messages="emailErrorMessage"
                                    validate-on-blur
                            ></v-text-field>

                            <v-text-field
                                    v-model="password"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    required
                                    :rules="validationRules.password"
                                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                    validate-on-blur
                            ></v-text-field>
                            <v-btn type="submit" color="primary my-5" depressed block large>Login</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>

    export default {
        name: "Login",

        data: () => {
            return {
                email: '',
                password: '',
                valid: false,
                showPassword: false,
                validationRules: {
                    password: [
                        v => v.length > 0 || 'Password is required.'
                    ],
                    email: [
                        input => input.length > 0 || 'Email is required.',
                        input => new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(input) || 'Validate email address is required'
                    ]

                },
                emailErrorMessage: ''

            }
        },
        methods: {
            login: function() {
                this.valid = true;
                this.$refs.form.validate();

                if(this.valid) {

                    this.$http.post('/admins/login',{
                        email: this.email,
                        password: this.password
                    })
                        .then(response => {
                            localStorage.setItem('WalkyTalkyAdminToken', response.data.jwt);
                            this.$store.methods.updateAdmin(response.data.admin);
                            this.$store.methods.updateLoggedIn(true);
                            this.$router.push(this.$route.query.redirect || '/');
                        })
                        .catch(error => {
                            if(error.response) {
                                var errors = error.response.data.errors;

                                for (const key of Object.keys(errors)) {
                                    if(errors[key].field === 'email') {
                                        this.emailErrorMessage = errors[key].error;
                                    }

                                }

                            }

                        });
                }
            }

        }
    }
</script>
