<template>
    <v-app>
        <v-navigation-drawer app class="elevation-5" v-if="$store.data.loggedIn">
            <template v-slot:prepend>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-regular title primary--text text-uppercase">
                            WalkyTalky Admin
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-list nav dense>
                <v-list-item-group color="primary">
                    <v-list-item
                        link
                        :to="item.path"
                        v-for="(item,key) in nav"
                        :key="key"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <template v-slot:append>
                <v-menu offset-x close-on-content-click rounded="l-0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-avatar color="primary">
                                <v-img :src="$store.computed.adminAvatarUrl()" ></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ $store.data.admin.name }}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </template>
                    <v-list dense>
                        <v-list-item to="/settings">
                            <v-list-item-icon>
                                <v-icon>mdi-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Settings</v-list-item-title>
                        </v-list-item>
                        <v-list-item to="/logout">
                            <v-list-item-icon>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-navigation-drawer>
        <v-main>
            <v-container fluid>
                <router-view/>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
export default {
    name: 'App',
    data: () => ({
        'nav': [
            {
                'title': 'Dashboard',
                'path': '/',
                'icon': 'mdi-view-dashboard-outline'
            },
            {
                'title': 'Sites',
                'path': '/sites',
                'icon': 'mdi-web'
            },
            {
                'title': 'Agents',
                'path': '/agents',
                'icon': 'mdi-account-multiple'
            },
            {
                'title': 'Reports',
                'path': '/reports',
                'icon': 'mdi-chart-bar'
            },
            {
                'title': 'Payments',
                'path': '/payments',
                'icon': 'mdi-cash-multiple'
            },
            {
                'title': 'Marketing Campaigns',
                'path': '/marketingCampaigns',
                'icon': 'mdi-bullhorn'
            },
            {
                'title': 'Promo Codes',
                'path': '/promoCodes',
                'icon': 'mdi-currency-usd'
            },

        ]
    })

}
</script>
