<template>
    <v-container fluid>
        <v-snackbar v-model="success" color="success" top :timeout="6000">
            {{ successMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="success = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="error" color="error" top :timeout="6000">
            <ul>
                <li v-for="(item,key) in errorMessages" :key="key">{{ item }}</li>
            </ul>
            <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="error = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col>
                <h1>Promo Codes</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="columns"
                        :items="promoCodes"
                        :loading="loading"
                        :server-items-length="totalPromoCodes"
                        :options.sync="options"
                        no-data-text="No Promo Codes Found"
                        :items-per-page="25"
                        :footer-props="footerProps"
                    >
                        <template v-slot:top>
                            <v-toolbar flat color="transparent">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="showNewModal = true;">New Campaign</v-btn>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.added_ts="{ item }">
                            {{ formatMysqlDateTime(item.added_ts) }}
                        </template>
                        <template v-slot:item.expiration_ts="{ item }">
                            {{ formatMysqlDateTime(item.expiration_ts) }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                            {{ item.amount }}%
                        </template>
                        <template v-slot:item.duration="{ item }">
                            {{ item.duration }} days
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon @click="selectedPromoCode = { ...item }; showEditModal = true;">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="selectedPromoCode = { ...item }; showDeleteModal = true;">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="showNewModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>New Promo Code</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="code"
                        label="Code"
                    ></v-text-field>
                    <v-text-field
                        v-model="description"
                        label="Description"
                    ></v-text-field>
                    <v-text-field
                        v-model="amount"
                        label="Discount Amount"
                        suffix="%"
                    ></v-text-field>
                    <v-text-field
                        v-model="duration"
                        label="Duration"
                        suffix="days"
                    ></v-text-field>
                    <v-menu
                        v-model="showExpirationDatePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="expirationDate"
                                label="Expiration Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                @click:clear="expirationDate = null"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="expirationDate"
                            @input="showExpirationDatePicker = false"
                        ></v-date-picker>
                    </v-menu>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showNewModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="createPromoCode">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showEditModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>Edit Promo Code</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="selectedPromoCode.code"
                        label="Code"
                    ></v-text-field>
                    <v-text-field
                        v-model="selectedPromoCode.description"
                        label="Description"
                    ></v-text-field>
                    <v-text-field
                        v-model="selectedPromoCode.amount"
                        label="Discount Amount"
                        suffix="%"
                    ></v-text-field>
                    <v-text-field
                        v-model="selectedPromoCode.duration"
                        label="Duration"
                        suffix="days"
                    ></v-text-field>
                    <v-menu
                        v-model="showEditExpirationDatePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="selectedPromoCode.expiration_ts"
                                label="Expiration Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="selectedPromoCode.expiration_ts"
                            @input="showEditExpirationDatePicker = false"
                        ></v-date-picker>
                    </v-menu>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="showEditModal=false;selectedPromoCode={};">Cancel</v-btn>
                    <v-btn text color="primary" @click="updatePromoCode">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showDeleteModal"
            max-width="500"
        >
            <v-card>
                <v-card-title>Delete Promo Code</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this promo code?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="selectedPromoCode={};showDeleteModal=false;">Cancel</v-btn>
                    <v-btn text color="error" @click="deletePromoCode">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import formatDateTime from "@/mixins/formatDateTime";
import formatNumber from "@/mixins/formatNumber";

export default {
    name: 'PromoCodesView',
    mixins: [formatDateTime, formatNumber],
    data: () => {
        return {
            success: false,
            successMessage: '',
            error: false,
            errorMessages: [],
            loading: false,
            showNewModal: false,
            showEditModal: false,
            showDeleteModal: false,
            promoCodes: [],
            selectedPromoCode: {},
            totalPromoCodes: 0,
            code: '',
            description: '',
            amount: '',
            duration: '',
            expirationDate: null,
            showExpirationDatePicker: false,
            showEditExpirationDatePicker: false,
            options: null,
            footerProps: {
                'items-per-page-options': [10, 25, 50, 100]
            },
            columns:[
                {text: 'ID', value: 'id'},
                {text: 'Description', value: 'description'},
                {text: 'Code', value: 'code'},
                {text: 'Created', value: 'added_ts'},
                {text: 'Expires', value: 'expiration_ts'},
                {text: 'Amount', value: 'amount', align: 'right'},
                {text: 'Duration', value: 'duration', align: 'right'},
                {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
            ],
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            try {
                const response = await this.$http.get('/promo_codes', {
                    'search': this.search,
                    'sort': this.options.sortBy,
                    'sortDesc': this.options.sortDesc,
                    'page': this.options.page,
                    'limit': this.options.itemsPerPage,
                });

                this.promoCodes = response.data.promo_codes;
                this.totalPromoCodes = response.data.total;
                this.loading = false;

            } catch(e) {
                console.error('error accessing campaigns');
            }

        },
        async createPromoCode() {
            try {
                await this.$http.post("/promo_codes", {
                    'code': this.code,
                    'description': this.description,
                    'amount': this.amount,
                    'duration': this.duration,
                    'expirationDate': this.expirationDate,
                })
                this.code = '';
                this.description = '';
                this.amount = '';
                this.duration = '';
                this.expirationDate = null;

                this.showNewModal = false;
                this.success = true;
                this.successMessage = "Promo Code Created!";
                this.getData();

            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }

        },
        async updatePromoCode() {
            try {
                await this.$http.post("/promo_codes/"+this.selectedPromoCode.id, this.selectedPromoCode)

                this.selectedPromoCode = {};
                this.showEditModal = false;
                this.success = true;
                this.successMessage = "Promo Code Updated!";
                this.getData();

            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }

        },
        async deletePromoCode() {
            try {
                await this.$http.post("/promo_codes/"+this.selectedPromoCode.id+'/delete')

                this.selectedPromoCode = {};
                this.showDeleteModal = false;
                this.success = true;
                this.successMessage = "Promo Code Deleted!";
                this.getData();

            } catch(e) {
                console.error(e);
                this.errorMessages = e.response.data.errors;
                this.error = true;

            }

        }
    },
    watch: {
        options: {
            handler(newVal, oldVal) {
                if(oldVal !== null) {
                    this.getData();
                }
            },
            deep: true
        },

    },
    mounted() {
        this.getData();
    }
}
</script>
