<template>
    <div>
        <v-card-subtitle>Revenue</v-card-subtitle>
        <v-card-text>
            <div class="text-h4 text--primary">{{ formatCurrency(revenueThisMonth) }}</div>
            <div :class="revenueDifference >= 0 ? 'success--text' : 'error--text'" v-if="!loading">
                <span v-if="revenueDifference >= 0">+</span>{{ formatCurrency(revenueDifference)}} vs last month
            </div>
        </v-card-text>
    </div>
</template>

<script>
import formatCurrency from "@/mixins/formatCurrency";
import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc')

export default {
    name: 'DashboardRevenueCard',
    mixins: [formatCurrency],
    data: () => {
        return {
            loading: true,
            revenueThisMonth: 0,
            revenueLastMonth: 0
        };
    },
    methods: {

        async getData() {
            try {
                const revenueThisMonthResponse = await this.$http.get('/payments/totals', {
                    'fromTs': dayjs.utc().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                    'toTs': dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
                });

                const revenueLastMonthResponse = await this.$http.get('/payments/totals', {
                    'fromTs': dayjs.utc().startOf('month').subtract(1,'month').format('YYYY-MM-DD HH:mm:ss'),
                    'toTs': dayjs.utc().subtract(1,'month').format('YYYY-MM-DD HH:mm:ss'),
                });

                this.revenueThisMonth = revenueThisMonthResponse.data.total;
                this.revenueLastMonth = revenueLastMonthResponse.data.total;

                this.loading = false;

            } catch (e) {
                console.error(e);
            }
        }
    },
    computed: {
        revenueDifference() {
            return this.revenueThisMonth - this.revenueLastMonth;
        },
    },
    mounted() {
        dayjs.extend(utc)
        this.getData();
    }
}
</script>
