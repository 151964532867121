<template>
    <Bar
        :data="chartData"
        :options="options"
        :styles="styles"
    />
</template>

<script>
// DataPage.vue
import { Bar } from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors)

export default {
    name: 'BarChart',
    components: { Bar },
    props: ['chartData','options','styles']
}
</script>
