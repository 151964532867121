import Vue from 'vue';

const appStore = Vue.observable({
    data: {
        appName: 'Walky Talky Admin',
        admin: {
            id: null,
            name: null,
            email: null,
            email_hash: null
        },
        loggedIn: false,
        loading: false,
        plans: [
            {
                'id': 1,
                'name': 'Live Chat Essentials',
            },
            {
                'id': 2,
                'name': 'User Management',
            },
            {
                'id': 3,
                'name': 'Campaigns',
            },
            {
                'id': 4,
                'name': 'Articles',
            },
            {
                'id': 5,
                'name': 'Response Bot',
            },
            {
                'id': 6,
                'name': 'Remove Branding',
            },

        ]
    },
    computed: {
        adminAvatarUrl() {
            return "https://www.gravatar.com/avatar/"+appStore.data.admin.email_hash;
        },
    },
    methods: {
        updateAdmin: function(admin) {
            appStore.data.admin = admin;
        },
        updateLoggedIn: function(status) {
            appStore.data.loggedIn = status;
        },
    }
});
export default appStore;
