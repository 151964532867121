<template>
    <v-card :loading="loading"
            class="mb-5"
    >
        <template slot="progress">
            <v-progress-linear
                indeterminate
            ></v-progress-linear>
        </template>
        <v-card-title>Platform Totals</v-card-title>
        <v-list>
            <v-list-item>
                <v-list-item-title>Agents</v-list-item-title>
                <v-list-item-action-text>{{ formatNumber(this.totalAgents) }}</v-list-item-action-text>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>Sites</v-list-item-title>
                <v-list-item-action-text>{{ formatNumber(this.totalSites) }}</v-list-item-action-text>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>Users</v-list-item-title>
                <v-list-item-action-text>{{ formatNumber(this.totalUsers) }}</v-list-item-action-text>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>Messages</v-list-item-title>
                <v-list-item-action-text>{{ formatNumber(this.totalMessages) }}</v-list-item-action-text>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import formatNumber from "@/mixins/formatNumber";

export default {
    name: 'DashboardPlatformTotals',
    mixins: [formatNumber],
    data: () => {
        return {
            loading: true,
            totalAgents: 0,
            totalSites: 0,
            totalUsers: 0,
            totalMessages: 0,
        };
    },
    methods: {

        async getData() {
            try {
                const agentsResponse = await this.$http.get('/agents/totals');
                this.totalAgents = agentsResponse.data.total;

                const sitesResponse = await this.$http.get('/sites/totals');
                this.totalSites = sitesResponse.data.total;

                const usersResponse = await this.$http.get('/users/totals');
                this.totalUsers = usersResponse.data.total;

                const messagesResponse = await this.$http.get('/chat_messages/totals');
                this.totalMessages = messagesResponse.data.total;

                this.loading = false;

            } catch (e) {
                console.error(e);
            }

        },
    },
    mounted() {
        this.getData();
    }
}
</script>
