<template>
    <div>
        <v-card-subtitle>New Agents</v-card-subtitle>
        <v-card-text>
            <div class="text-h4 text--primary">{{ formatNumber(agentsThisMonth) }}</div>
            <div :class="agentsDifference >= 0 ? 'success--text' : 'error--text'" v-if="!loading">
                <span v-if="agentsDifference >= 0">+</span>{{ formatNumber(agentsDifference)}} vs last month
            </div>
        </v-card-text>
    </div>
</template>

<script>
import formatNumber from "@/mixins/formatNumber";
import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc')

export default {
    name: 'DashboardAgentsCard',
    mixins: [formatNumber],
    data: () => {
        return {
            loading: true,
            agentsThisMonth: 0,
            agentsLastMonth: 0
        };
    },
    methods: {

        async getData() {
            try {
                const agentsThisMonthResponse = await this.$http.get('/agents/totals', {
                    'fromTs': dayjs.utc().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                    'toTs': dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
                });

                const agentsLastMonthResponse = await this.$http.get('/agents/totals', {
                    'fromTs': dayjs.utc().startOf('month').subtract(1,'month').format('YYYY-MM-DD HH:mm:ss'),
                    'toTs': dayjs.utc().subtract(1,'month').format('YYYY-MM-DD HH:mm:ss'),
                });

                this.agentsThisMonth = agentsThisMonthResponse.data.total;
                this.agentsLastMonth = agentsLastMonthResponse.data.total;

                this.loading = false;

            } catch (e) {
                console.error(e);
            }
        }
    },
    computed: {
        agentsDifference() {
            return this.agentsThisMonth - this.agentsLastMonth;
        },
    },
    mounted() {
        dayjs.extend(utc)
        this.getData();
    }
}
</script>
