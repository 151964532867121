<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1>Sites</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>

                        <v-row>
                            <v-col cols="4">
                                <v-select
                                    class="pt-0 mt-0"
                                    hide-details
                                    label="Site Status"
                                    :items="statusFilter"
                                    v-model="status"
                                    @change="performSearch"
                                ></v-select>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search by Name, ID or Owner's Name"
                                    single-line
                                    hide-details
                                    @keypress.exact.enter="performSearch"
                                    @click:append="performSearch"
                                    class="pt-0 mt-0"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="columns"
                        :items="sites"
                        :loading="loading"
                        :server-items-length="totalSites"
                        :options.sync="options"
                        no-data-text="No Sites Found"
                        :items-per-page="25"
                        :footer-props="footerProps"
                    >
                        <template v-slot:item.added_ts="{ item }">
                            {{ formatMysqlDateTime(item.added_ts) }}
                        </template>
                        <template v-slot:item.url="{ item }">
                            <a :href="'https://'+item.url" target="_blank" rel="noopener noreferrer">
                                <div class="d-inline-block text-truncate" style="max-width: 300px;">
                                    {{ item.url }}
                                </div>
                            </a>
                        </template>
                        <template v-slot:item.last_verified_date="{ item }">
                            <v-icon
                                color="success"
                                v-if="parseInt(item.verified_status) === 1"
                                :title="item.last_verified_date"
                            >mdi-check-circle</v-icon>
                            <v-icon
                                color="error"
                                v-else-if="parseInt(item.verified_status) === 2"
                                :title="item.last_verified_date + ' - ' + item.verified_domain"
                            >mdi-alert</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon :to="'/sites/'+item.id">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import formatDateTime from "@/mixins/formatDateTime";

export default {
    name: 'SitesView',
    mixins: [formatDateTime],
    data: () => {
        return {
            loading: false,
            sites: [],
            totalSites: 0,
            options: null,
            search: '',
            status: 1,
            statusFilter: [
                {
                    value: 2,
                    text: 'All'
                },
                {
                    value: 1,
                    text: 'Active'
                },
                {
                    value: 0,
                    text: 'Deleted'
                },
            ],
            footerProps: {
                'items-per-page-options': [10, 25, 50, 100]
            },
            columns:[
                {text: 'ID', value: 'id'},
                {text: 'Site Name', value: 'site_name'},
                {text: 'Address', value: 'url'},
                {text: 'Verified', value: 'last_verified_date'},
                {text: 'Added', value: 'added_ts'},
                {text: 'Owner', value: 'agent_name'},
                {text: 'Actions', value: 'actions', sortable:false}
            ],
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            try {
                const response = await this.$http.get('/sites', {
                    search: this.search,
                    'sort': this.options.sortBy,
                    'sortDesc': this.options.sortDesc,
                    'page': this.options.page,
                    'limit': this.options.itemsPerPage,
                    'status': this.status
                });

                this.sites = response.data.sites;
                this.totalSites = response.data.total;
                this.loading = false;

            } catch(e) {
                console.error('error accessing sites');
            }

        },
        performSearch() {
            this.options.page = 1;
            this.getData();
        }
    },
    watch: {
        options: {
            handler(newVal, oldVal) {
                if(oldVal !== null) {
                    this.getData();
                }
            },
            deep: true
        },

    },
    mounted() {
        this.getData();
    }
}
</script>
