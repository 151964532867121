import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import LoginView from '../views/LoginView.vue'
import LogoutView from '../views/LogoutView.vue'
import SettingsView from '../views/SettingsView.vue'
import SitesView from '../views/SitesView.vue'
import SiteView from '../views/SiteView.vue'
import AgentsView from '../views/AgentsView.vue'
import AgentView from '../views/AgentView.vue'
import PaymentsView from "../views/PaymentsView.vue";
import MarketingCampaignsView from "../views/MarketingCampaignsView.vue";
import PromoCodesView from "../views/PromoCodesView.vue";
import ReportsView from "../views/ReportsView.vue";
import ReportsUsersBySite from "../components/ReportsUsersBySite.vue";
import ReportsMessagesBySite from "../components/ReportsMessagesBySite.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutView
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView
    },
    {
        path: '/sites',
        component: SitesView,
        name: 'sites',
    },
    {
        path: '/sites/:id',
        name: 'site',
        component: SiteView
    },
    {
        path: '/agents',
        component: AgentsView,
        name: 'agents',
    },
    {
        path: '/agents/:id',
        component: AgentView,
        name: 'agent',
    },
    {
        path: '/payments',
        component: PaymentsView,
        name: 'payments',
    },
    {
        path: '/marketingCampaigns',
        component: MarketingCampaignsView,
        name: 'marketingCampaigns',
    },
    {
        path: '/promoCodes',
        component: PromoCodesView,
        name: 'promoCodes',
    },
    {
        path: '/reports',
        component: ReportsView,
        name: 'reports',
        children: [
            { path: 'usersBySite', component: ReportsUsersBySite, name: 'reportsUsersBySite' },
            { path: 'messagesBySite', component: ReportsMessagesBySite, name: 'reportsMessagesBySite' },
        ]
    },
]

const router = new VueRouter({
    routes
})

export default router
