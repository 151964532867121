<template>
    <v-card class="mb-5">
        <v-card-title>This Month</v-card-title>
        <dashboard-agents-card></dashboard-agents-card>
        <v-divider></v-divider>
        <dashboard-sites-card></dashboard-sites-card>
        <v-divider></v-divider>
        <dashboard-revenue-card></dashboard-revenue-card>
    </v-card>
</template>

<script>
import DashboardRevenueCard from "@/components/DashboardRevenueCard.vue";
import DashboardSitesCard from "@/components/DashboardSitesCard.vue";
import DashboardAgentsCard from "@/components/DashboardAgentsCard.vue";

export default {
    name: 'DashboardThisMonthCard',
    components: {
        DashboardAgentsCard, DashboardSitesCard, DashboardRevenueCard
    },
}
</script>
