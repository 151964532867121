import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import api from './api'
import appStore from './store'

var heartbeatTimeout = null;
Vue.config.productionTip = false

Vue.prototype.$http = api;
Vue.prototype.$store = appStore;

function heartbeat () {
    var routeName = router.currentRoute.name;
    if (routeName !== 'login' && routeName !== 'logout') {
        //check login
        api.get('/admins/session')
            .then(response => {
                localStorage.setItem('WalkyTalkyAdminToken', response.data.token);
                if(!appStore.data.loggedIn) {
                    appStore.methods.updateAdmin(response.data.admin);
                    appStore.methods.updateLoggedIn(true);
                }
            })
            .catch(response => {
                if(response.response) {
                    appStore.methods.updateAdmin({});
                    appStore.methods.updateLoggedIn(false);
                    router.push({ path: '/login'});
                }
            })
    }
    if(typeof heartbeatTimeout !== 'undefined') {
        window.clearTimeout(heartbeatTimeout);
    }
    heartbeatTimeout = window.setTimeout(heartbeat, 60000);
}

router.beforeEach((to, from, next) => {

    if(to.meta.title) {
        document.title = to.meta.title+' - WalkyTalky Admin';
    } else {
        document.title = 'WalkyTalky Admin';
    }

    if(to.name !== 'login' && to.name !== 'logout') {
        api.get('/admins/session')
            .then(response => {
                localStorage.setItem('WalkyTalkyAdminToken', response.data.token);
                if(!appStore.data.loggedIn) {
                    appStore.methods.updateAdmin(response.data.admin);
                    appStore.methods.updateLoggedIn(true);
                }
                next();
            })
            .catch(response => {
                if(response.response) {
                    next({
                        name: 'login',
                        query: { redirect: to.fullPath }
                    });
                }
            })
    } else {
        next();
    }

    heartbeat();
    heartbeatTimeout = window.setTimeout(heartbeat, 60000);
});

new Vue({
    vuetify,
    router,
    api,
    render: h => h(App)
}).$mount('#app')
